<!-- // eslint-disable-next-line vue/multi-word-component-names -->
<script setup>
import { ref, computed, onMounted, watchEffect, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n'
// import { useHead } from '@vueuse/head'
// import LayerOut from '@/components/LayerOut.vue'
// import footerPage from '@/components/footerPage.vue'
// import { useStore } from 'vuex'
// import axios from 'axios';
import { useUserStore, activeDivStore } from '@/store/index';
import axiosInstance from '@/axios.config'; // 导入配置好的 Axios 实例
import { fetchImage } from '@/utils/fetchImage';
import ImageViewer from './ImageViewer.vue';
import Compressor from 'compressorjs';
// import { useRoute } from 'vue-router';
import { showImageViewer, closeImageViewer, imageViewerVisible, currentImageUrl, downloadImage } from '@/imageViewerHandler';
import ContentFaceSwap from '@/components/ContentFaceSwap.vue';
import { useHead } from '@vueuse/head'
const userStore = useUserStore();
const useActiveDivStore = activeDivStore()
const isHoveringA = ref(false);
const isHoveringB = ref(false);
const supportedLocales = [
	"en",
	"ar",
	"de",
	"es",
	"fr",
	"hi",
	"it",
	"ja",
	"ko",
	"nl",
	"pt",
	"ru",
	"tr",
	"vi",
	"zhCN",
	"zhTW",
];
// 动态设置 lang 属性
const getLang = (locale) => {
	switch (locale) {
		case 'zhCN':
			return 'zh-CN';
		case '':
			return 'en';
		case 'undefined':
			return 'en';
		case 'zhTW':
			return 'zh-TW';
		default:
			return locale;
	}
};
// 语言支持
const { t, locale } = useI18n();

// 控制放大图显示原图的弹窗
const ogUrl = ref("https://www.imagefaceswap.com/face-swap")
// 修改主useHead配置
useHead({
	title: t('title_index'),
	htmlAttrs: {
		lang: getLang(locale.value),
	},
	meta: [
		{ name: "robots", content: "index,follow" },
		{ name: "googlebot", content: "index,follow" },
		{ name: 'description', content: t('description_index') },
		{ name: 'keywords', content: t('keywords_index') },
		{ property: 'og:title', content: t('title_index') },
		{ property: 'og:description', content: t('description_index') },
		{ property: 'og:url', content: ogUrl.value },
		{ name: 'twitter:title', content: t('title_index') },
		{ name: 'twitter:description', content: t('description_index') },
		{ name: 'twitter:url', content: ogUrl.value },
		{ name: 'lang', content: locale.value }
	],
	link: [
		// 动态设置canonical
		{
			rel: 'canonical',
			href: locale.value === 'en'
				? 'https://www.imagefaceswap.com/face-swap'
				: `https://www.imagefaceswap.com/${locale.value}/face-swap`
		},
		// 生成alternate链接
		...supportedLocales.map(lang => ({
			rel: 'alternate',
			hreflang: lang,
			href: lang === 'en'
				? 'https://www.imagefaceswap.com/face-swap'
				: `https://www.imagefaceswap.com/${lang}/face-swap`
		}))
	]
})

// 修改watchEffect中的处理
watchEffect(() => {
	const currentLang = userStore.lang;
	locale.value = currentLang;

	useHead({
		title: t('title_enhancer'),
		htmlAttrs: {
			lang: getLang(currentLang),
		},
		meta: [
			{ name: "robots", content: "index,follow" },
			{ name: "googlebot", content: "index,follow" },
			{ name: 'description', content: t('description_index') },
			{ name: 'keywords', content: t('keywords_index') },
			{ property: 'og:title', content: t('title_index') },
			{ property: 'og:description', content: t('description_index') },
			{ property: 'og:url', content: ogUrl.value },
			{ name: 'twitter:title', content: t('title_index') },
			{ name: 'twitter:description', content: t('description_index') },
			{ name: 'twitter:url', content: ogUrl.value },
			{ name: 'lang', content: locale.value }
		],
		link: [
			{
				rel: 'canonical',
				href: currentLang === 'en'
					? 'https://www.imagefaceswap.com/face-swap'
					: `https://www.imagefaceswap.com/${currentLang}/face-swap`
			},
			...supportedLocales.map(lang => ({
				rel: 'alternate',
				hreflang: lang,
				href: lang === 'en'
					? 'https://www.imagefaceswap.com/face-swap'
					: `https://www.imagefaceswap.com/${lang}/face-swap`
			}))
		]
	})
})


const steps = ref([
	'face swapping',
	'face_restoration',
	'enhance_detail'
]);

//语言支持



const handleImageClick = (event) => {
	const imageUrl = event.target.src;
	showImageViewer(imageUrl);
};

const isLoggedIn = userStore.isLoggedIn;
onMounted(() => {
	if (!isLoggedIn.value) {
		userStore.updateNoneLoginUserInfo()
	} else {
		userStore.fetchCredits()
	}
});


const user = computed(() => userStore.isLoggedIn ? userStore.user : userStore.guestUser);

const userCredits = computed(() => userStore.isLoggedIn ? userStore.userCredit : userStore.guestCredit);

const loading = ref(false);


const fileInput = ref(null);
const templateInput = ref(null);
const contentBox = ref(null)
const index = ref(0)
// const userFace = ref(null)
// const template = ref([])
const isImageUploaded = ref(false);
const activeDiv = ref('swap_face');
const user_curent_task_id = ref(null)


// 上传图片
const imageA = ref(null)
const imageB = ref([])
const imageUrlA = ref(null)
const imageUrlB = ref(null)
const show_downLoad = ref(false)
const isUploading = ref(false)
const mylib_result_urls = computed(() => userStore.mylib_result);

// 生成的结果:
const result_url = ref(null)

watchEffect(() => {
	// console.log(useActiveDivStore.activeDiv)
	if (useActiveDivStore.activeDiv) {
		activeDiv.value = useActiveDivStore.activeDiv
	}
	if (activeDiv.value === 'my_result' || userStore.mylib_result) {
		// console.log("监控数据",userStore.getters.getList)
		mylib_result_urls.value = userStore.getters.getList
	}

})

const toggleDisplay = (div) => {
	useActiveDivStore.updateactiveDiv(div);

	// console.log("glabal ",useActiveDivStore.activeDiv)
	// // 如果切换到 'my_result'，则请求后端获取结果
	// activeDiv.value = useActiveDivStore.activeDiv
	if (div === 'my_result') {
		// console.log("glabal ", useActiveDivStore.activeDiv)
		userStore.fetchResultUrls();
		// console.log("userStore.mylib_result", userStore.mylib_result)
		// mylib_result_urls.value = userStore.mylib_result
	}
};



const categories = ref([]);
const selectedCategory = ref(null)
const selectedImage = ref(null)



onMounted(async () => {
	try {
		const response = await axiosInstance.get('/api/swap_face/categories_template')
		categories.value = response.data
		if (categories.value.length > 0) {
			selectedCategory.value = { category: categories.value[0], index: 0 }
			selectedImage.value = categories.value[0].images[0]
			imageUrlB.value = selectedImage.value
		}
	} catch (error) {
		// console.error('获取分类数据失败:', error)
		categories.value = [
			{
				'name': 'head shot',
				'images': [
					'https://gd-hbimg.huaban.com/fab6a29b55d14094426bf8c8e32074bb493544cab6a8-pS50M2_fw1200',
				]
			}]
		selectedCategory.value = { category: categories.value[0], index: 0 }
		selectedImage.value = categories.value[0].images[0]
		imageUrlB.value = selectedImage.value
	}
})

const selectCategory = (category, newIndex) => {
	selectedCategory.value = { category, index: newIndex };
	// selectImage(category.images[0]);
	index.value = index;
};

const selectImage = (image, remoteImage) => {
	// console.log('image selected')
	selectedImage.value = image;
	imageUrlB.value = remoteImage
	show_downLoad.value = false
	// console.log('image selected')
};

const openFileInput = () => {
	fileInput.value.click()
	show_downLoad.value = false
}

const openFileTemptInput = () => {
	templateInput.value.click()
	show_downLoad.value = false
}



const handleTemptFileChange = (event) => {
	const files = event.target.files
	result_url.value = null
	isUploading.value = true
	imageUrlB.value = null
	for (let i = 0; i < files.length; i++) {
		const file = files[i]
		// const reader = new FileReader()
		// reader.onload = async (e) => {
		// 	const img_url = await uploadFile(file, 'your template')
		// 	selectedImage.value = img_url
		// 	imageUrlB.value = img_url
		// 	show_downLoad.value = false
		//   imageB.value.unshift({
		//     file,
		// 		url: await fetchImage(img_url),
		// 		remote_url: img_url,
		//   })
		//   // uploadFile(file, 'B')
		// }
		// reader.readAsDataURL(file)
		// 使用 compressorjs 进行图片压缩
		new Compressor(file, {
			quality: 0.6, // 压缩质量，0.6 表示 60% 的质量
			maxWidth: 1024, // 最大宽度
			maxHeight: 1024, // 最大高度
			success(compressedFile) {
				const reader = new FileReader();
				reader.onload = async (e) => {
					const img_url = await uploadFile(compressedFile, 'your template');
					selectedImage.value = img_url;
					imageUrlB.value = img_url;
					show_downLoad.value = false;
					imageB.value.unshift({
						file: compressedFile,
						url: await fetchImage(img_url),
						remote_url: img_url,
					});
				};
				reader.readAsDataURL(compressedFile);
			},
			error(err) {
				console.error('Compression error:', err.message);
			},
		});
	}
}

const handleTemplateDrop = (event) => {
	result_url.value = null
	isUploading.value = true
	imageUrlB.value = null
	const files = event.dataTransfer.files;
	for (let i = 0; i < files.length; i++) {
		const file = files[i]
		// const reader = new FileReader()
		// reader.onload = async (e) => {
		// 	const img_url = await uploadFile(file, 'your template')
		// 	selectedImage.value = img_url
		// 	imageUrlB.value = img_url
		// 	show_downLoad.value = false
		//   imageB.value.unshift({
		//     file,
		// 		url: await fetchImage(img_url),
		// 		remote_url: img_url,
		//   })
		//   // uploadFile(file, 'B')
		// }
		// reader.readAsDataURL(file)
		// 使用 compressorjs 进行图片压缩
		new Compressor(file, {
			quality: 0.6, // 压缩质量，0.6 表示 60% 的质量
			maxWidth: 1024, // 最大宽度
			maxHeight: 1024, // 最大高度
			success(compressedFile) {
				const reader = new FileReader();
				reader.onload = async (e) => {
					const img_url = await uploadFile(compressedFile, 'your template');
					selectedImage.value = img_url;
					imageUrlB.value = img_url;
					show_downLoad.value = false;
					imageB.value.unshift({
						file: compressedFile,
						url: await fetchImage(img_url),
						remote_url: img_url,
					});
				};
				reader.readAsDataURL(compressedFile);
			},
			error(err) {
				console.error('Compression error:', err.message);
			},
		});
	}

}

const handleFaceDrop = (event) => {
	result_url.value = null
	isUploading.value = true
	isHoveringA.value = false;
	imageA.value = null
	imageUrlA.value = null
	const file = event.dataTransfer.files[0];
	if (file) {
		// 使用 compressorjs 进行图片压缩
		new Compressor(file, {
			quality: 0.6, // 压缩质量，0.6 表示 60% 的质量
			maxWidth: 1024, // 最大宽度
			maxHeight: 1024, // 最大高度
			success(compressedFile) {
				// 上传压缩后的文件
				uploadFile(compressedFile, 'your face').then((img_url) => {
					isImageUploaded.value = true; // 上传图片后改变按钮 B 的颜色
					fetchImage(img_url).then((url) => {
						imageUrlA.value = url;
						imageA.value = url;
						// console.log("imageUrlA", imageUrlA.value);
						// console.log("imageA", imageA.value);
						isUploading.value = false; // 上传完成后将 isUploading 设置为 false
					});
				});
			},
			error(err) {
				console.error('Compression error:', err.message);
				isUploading.value = false; // 压缩失败后将 isUploading 设置为 false
			},
		});
	} else {
		isUploading.value = false; // 如果没有文件选择，将 isUploading 设置为 false
	}
};

let pollingInterval = null;

const handleFileChange = async (event) => {

	isUploading.value = true
	isHoveringA.value = false;
	const file = event.target.files[0]
	if (file) {
		// 使用 compressorjs 进行图片压缩
		new Compressor(file, {
			quality: 0.6, // 压缩质量，0.6 表示 60% 的质量
			maxWidth: 1024, // 最大宽度
			maxHeight: 1024, // 最大高度
			success(compressedFile) {
				// 上传压缩后的文件
				uploadFile(compressedFile, 'your face').then((img_url) => {
					isImageUploaded.value = true; // 上传图片后改变按钮 B 的颜色
					fetchImage(img_url).then((url) => {
						imageUrlA.value = url;
						imageA.value = url;
						// console.log("imageUrlA", imageUrlA.value);
						// console.log("imageA", imageA.value);
						isUploading.value = false; // 上传完成后将 isUploading 设置为 false
					});
				});
			},
			error(err) {
				console.error('Compression error:', err.message);
				isUploading.value = false; // 压缩失败后将 isUploading 设置为 false
			},
		});
	} else {
		isUploading.value = false; // 如果没有文件选择，将 isUploading 设置为 false
	}
}

const uploadFile = async (file, type) => {
	const formData = new FormData()
	formData.append('file', file)
	try {
		const response = await axiosInstance.post('/api/swap_face/upload', formData)
		if (response.status == 200) {
			isUploading.value = false
			return response.data.img_url
		} else {
			alert(type + " image upload faild,please check your picture and reload upload again!")
			isUploading.value = false
			loading.value = false;
			imageUrlA.value = null
			imageUrlB.value = null
			selectedImage.value = null
			imageA.value = null
			isImageUploaded.value = false
			show_downLoad.value = false
		}
	} catch (error) {
		alert('image upload faild,please check your picture', error)
		console.error('image upload faild,please check your picture:', error)
		isUploading.value = false
		loading.value = false;
		imageUrlA.value = null
		imageUrlB.value = null
		selectedImage.value = null
		imageA.value = null
		isImageUploaded.value = false
		show_downLoad.value = false
		// return null
	}
}

const pollTaskResult = () => {
	// if (pollingInterval) {
	// 	console.log('轮询任务已存在，不再启动新的轮询任务');
	// 	return;
	// }
	pollingInterval = setInterval(async () => {
		try {
			const response = await axiosInstance.post(`/api/swap_face/result`, {
				'task_id': user_curent_task_id.value, 'isLogin': userStore.isLoggedIn
			});
			if (response.status === 200 || response.status === 400) {
				// console.log("result",response.data)
				const result = response.data;
				if (result.status === 'completed') {
					// 任务完成，获取下载链接
					result_url.value = result.download_url;
					clearInterval(pollingInterval); // 停止轮询
					loading.value = false; // 隐藏动效
					show_downLoad.value = true
				}
			}
		} catch (error) {
			clearInterval(pollingInterval); // 停止轮询
			loading.value = false; // 隐藏动效
		}
	}, 3000); // 每 5 秒轮询一次
};


onUnmounted(() => {
	clearInterval(pollingInterval); // 组件卸载时停止轮询
});

const buttonText = ref(null)
watchEffect(() => {
	if (show_downLoad.value) {
		buttonText.value = "download"
	} else if (loading.value) {
		buttonText.value = "notify me"
	} else {
		buttonText.value = "swap face"
	}
});

const handleClick = async () => {
	if (loading.value) {
		// alert('生成任务已在后台进行，请稍候...');
		if (pollingInterval) {
			clearInterval(pollingInterval);
			// console.log('轮询已停止');
		}
		loading.value = false;
		imageUrlA.value = null
		imageUrlB.value = null
		selectedImage.value = null
		imageA.value = null
		isImageUploaded.value = false
		show_downLoad.value = false
		return;
	}

	if (!imageUrlA.value || !imageUrlB.value) {
		alert('please upload image');
		return;
	}
	loading.value = true;
	try {
		if (show_downLoad.value) {
			await downloadImage(result_url.value);
			loading.value = false; // 隐藏动效
		} else {
			if (userCredits.value < 2) {
				// console.log("need buy credists")
				userStore.showSubcrib = true
				loading.value = false
				return
			} else { await generate(0); }
		}
	} catch (error) {
		console.error(error);
		// loading.value = false;
	} finally {
		// loading.value = false;
	}
};


const generate = async (needCredit) => {
	// 通过变量控制是否每过 10s轮询一次/用户点击完成通知我
	result_url.value = null
	if (!imageUrlA.value) {
		alert("please upload face image")
	}

	if (!imageUrlB.value) {
		alert("please upload template image")
	}

	loading.value = true; // 显示动效
	if (imageUrlA.value && imageUrlB.value) {
		try {
			const response = await axiosInstance.post('/api/swap_face/create', {
				udid: user.value.udid,
				image_url: imageUrlA.value,
				template_url: imageUrlB.value,
				task_type: "swap_face",
				task_id: Date.now(),
				is_custom: false,
				isLogin: userStore.isLoggedIn
			})
			if (response.status == 200) {

				// 任务创建成功
				userStore.consumeCredits(needCredit)
				user_curent_task_id.value = response.data.task_id
				// 开始轮询任务结果
				pollTaskResult();

			} else {
				alert("task faild", response.data.error)
			}
		} catch (error) {
			console.error('image process erro:', error)
		}
	}

}



// // 定义博客链接数据
// const blogLinks = ref([
// 	{ path: '/blog/1', label: 'Blog 1' },
// 	// { path: '/blog/2', label: 'Blog 2' },
// 	// { path: '/blog/3', label: 'Blog 3' },
// 	// 添加更多链接
// ]);


</script>

<script>
export default {
	'name': 'SwapFace'
}
</script>


<template>
	<div class="container">
		<ImageViewer :imageUrl="currentImageUrl" :visible="imageViewerVisible" @close="closeImageViewer" />
		<div class="content_box" ref="contentBox">
			<div class="swap_box content_box_height content_weight">
				<div class="swap_word1"> <!-- Free Face Swap Online -->
					<h1 class="h1">
						{{ $t('content_title') }}
					</h1>
				</div>
				<div class="swap_word2"> <!-- Discover realistic and funny face swap effects with our tool. -->
					<p>
						{{ $t('content_') }}
					</p>
				</div>
				<div class="font3_box">
					<button @click="toggleDisplay('swap_face')"
						:class="{ 'font_name3': true, isActive: activeDiv === 'swap_face' }">
						{{ $t('face_swap') }}
					</button>
					<button @click="toggleDisplay('multi_swap_face')" style="display: none;"
						:class="{ 'font_name3': true, isActive: activeDiv === 'multi_swap_face' }">
						multi face swap
					</button>
					<button @click="toggleDisplay('my_result')" class="font_name3"
						:class="{ 'font_name3': true, 'isActive': activeDiv === 'my_result' }">
						{{ $t('my_result') }}
					</button>
				</div>
				<div v-if="activeDiv === 'multi_swap_face'" style="width: 100%;height: auto;">
					multi swap face
				</div>
				<div v-if="activeDiv === 'my_result'"
					style="width: 100%;height: auto; display: flex;flex-wrap: wrap;overflow: scroll;">
					<!-- <img v-for=" (image, index) in selectedCategory.category.images" :key="index" :src="image" alt="Image"
						style="width: 20%;height: auto; float: left;" /> -->
					<img v-for="(image, index) in mylib_result_urls" :key="index" :src="image" alt="Image"
						style="max-width: 128px;max-height: 128px;padding: 10px; float: left;" @click="handleImageClick" />
				</div>
				<div v-if="activeDiv === 'swap_face'" class="row_content row_content_margin">
					<div class="row_content row_content_margin_child row_content_back">
						<imageProcessoring v-if="loading" :steps="steps" />
						<button @click="openFileInput" :disabled="isUploading"
							:class="{ 'col_content': true, 'row_content_margin_child': true, 'hover': isHoveringA }"
							style="height: 80%; width: 80%; border: none;" @dragover.prevent @drop.prevent="handleFaceDrop"
							@dragenter="isHoveringA = true" @dragleave="isHoveringA = false">
							<!-- <div class="row_content row_content_margin_child upload_img" style="height: 80%; width: 80%;">
							</div> -->
							<input class="row_content row_content_margin_child" style="display: none;" type="file" ref="fileInput"
								@change="handleFileChange" accept="image/*" />
							<div v-if="isUploading && !imageA" class="loading-spinner"></div>
							<div v-else>
								<img v-if="imageA" :src="imageA" alt="" style="width: 50px;height: auto;border-radius: 50%;">
								<img v-else src="../assets/upload-img.png" alt="" style="width: 100%;height: auto;">
								<!-- <img :src="imageA" alt="" style="width: 100%;height: auto;"> -->
							</div>
							<div> {{ $t('upload_face') }}</div>
						</button>
						<div class="row_content" style="align-items: center; justify-content: center;">
							<svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M12 12L24 24L12 36" stroke="#080808" stroke-width="2" stroke-linecap="butt"
									stroke-linejoin="round" />
								<path d="M24 12L36 24L24 36" stroke="#080808" stroke-width="2" stroke-linecap="butt"
									stroke-linejoin="round" />
							</svg>
						</div>
						<button @click="openFileTemptInput" :disabled="isUploading"
							:class="{ 'row_content': true, 'row_content_margin_child': true, 'hover': isHoveringB }"
							style="border: none;align-items: center;justify-content: center;" @dragover.prevent
							@drop.prevent="handleTemplateDrop" @dragenter="isHoveringB = true" @dragleave="isHoveringB = false">
							<div v-if="isUploading && !imageUrlB" class="loading-spinner">
							</div>
							<div v-if="selectedImage">
								<img :src="selectedImage" alt="" style="width: 100%;height: auto;">
							</div>
							<div v-else>
								<img src="../assets/upload-img.png" alt="" style="width: 100%;height: auto;">
							</div>
							<input class="row_content row_content_margin_child" style="display: none;" type="file" ref="templateInput"
								@change="handleTemptFileChange" accept="image/*" />
						</button>
						<!-- <button @click="openFileTemptInput" :disabled="isUploading"
							class="row_content row_content_margin_child img upload_img"
							:style="{backgroundImage: `url(${selectedImage})` }">
							<div v-if="isUploading" class="loading-spinner">
							</div>
							<div>
								<img src="${selectedImage}" alt="">
							</div>
							<input class="row_content row_content_margin_child" style="display: none;" type="file" ref="templateInput"
								@change="handleTemptFileChange" accept="image/*" />
						</button> -->
					</div>
					<div style="display: flex;align-items: center;justify-content: center;width: 50%;">
						<img v-if="result_url" :src="result_url" class="row_content row_content_margin_child img"
							@click="handleImageClick">
						<div v-if="!result_url && loading" class="loading-spinner"></div>
					</div>
				</div>
				<button v-if="activeDiv !== 'my_result'"
					:class="{ 'click_generate_upload': isImageUploaded, 'click_generate': !isImageUploaded }"
					@click="handleClick">

					<span v-if="!loading">{{ buttonText }}</span>
					<span v-else class="loading-spinner"></span>
					<span v-if="loading">{{ buttonText }}</span>
				</button>

				<!-- <button v-if="activeDiv !== 'my_result' && show_downLoad == false"
					:class="{'click_generate_upload': isImageUploaded, 'click_generate': !isImageUploaded }" @click="generate(2)">
					swap face
					<span v-if="loading" class="loading-spinner"></span>
				</button>
				<button v-if="activeDiv !== 'my_result' && show_downLoad == true "
					:class="{ 'click_generate_upload': isImageUploaded, 'click_generate': !isImageUploaded }"
					@click="downloadImage()">
					download
					<span v-if="loading" class="loading-spinner"></span>
				</button> -->
			</div>
			<div class="tempalte_box row_content">

				<div class="template_type">
					<button v-for="(category, index) in categories" :key="index" @click="selectCategory(category, index)"
						:class="{ 'template_type_select': true, isActive: selectedCategory.category === category }">
						{{ category.name }}
					</button>
				</div>
				<div class="template_img">
					<button class="upload_template" @click="openFileTemptInput">
						<svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
							<mask id="icon-4127d3cd7f10b972" maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="48"
								style="mask-type: alpha">
								<path d="M48 0H0V48H48V0Z" fill="#f3eeee" />
							</mask>
							<g mask="url(#icon-4127d3cd7f10b972)">
								<path d="M6 24.0083V42H42V24" stroke="#f3eeee" stroke-width="2" stroke-linecap="butt"
									stroke-linejoin="round" />
								<path d="M33 15L24 6L15 15" stroke="#f3eeee" stroke-width="2" stroke-linecap="butt"
									stroke-linejoin="round" />
								<path d="M23.9917 32V6" stroke="#f3eeee" stroke-width="2" stroke-linecap="butt"
									stroke-linejoin="round" />
							</g>
						</svg>
						<div
							style="margin-left: 5px;width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;font-size: 1rem;">
							{{ $t('upload_template') }}
						</div>
					</button>
					<div v-if="selectedCategory" class="content_box_height"
						style="margin-top: 80px;align-items: center;justify-content: center;overflow:auto;margin-right: 10px;">
						<input style="display: none;" type="file" ref="templateInput" @change="handleTemptFileChange" multiple />
						<!-- <div v-for="(img, index) in imageB" :key="index"> -->
						<div v-if="imageB.length">
							<img v-for="(img, index) in imageB" :key="index" :src="img.url" alt="Image B"
								@click="selectImage(img.url, img.remote_url)" :class="{ 'selected-image': selectedImage === img.url }"
								style="max-width: 200px;">
						</div>
						<img v-for="(image, index) in selectedCategory.category.images" :key="index" :src="image" alt="Image"
							@click="selectImage(image, image)" :class="{ 'selected-image': selectedImage === image }" />
					</div>
				</div>
			</div>
		</div>
		<ContentFaceSwap />
		<!-- <button class="back-to-top" @click="scrollToTop">Back to Top</button> -->
	</div>
</template>

<style scoped lang="css">
.h1 {
	font-size: 2vw;
	margin-top: 5rem;
}

.question-item {
	width: 85%;
	/* text-align: left; */
	margin-left: 0px auto;
	margin-bottom: 10px;
	color: #1f1f1f;
	/* border: #a7b0ba; */
	padding: 10px;
	border-radius: 5px;

}

.question-title {
	display: flex;
	align-items: center;
	background: #f5f2f4;
	;
	width: 90%;
	padding: 20px;
	height: 1.3rem;
	cursor: pointer;
	font-weight: bold;
	border-radius: 10px;
}

.question-answer {
	color: #b8b3b3;
	margin-top: 10px;
	padding-left: 20px;
}

.popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
}

.popup-content {
	background-color: white;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	position: relative;
}

.close {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 20px;
	cursor: pointer;
}

form {
	display: flex;
	flex-direction: column;
}

label {
	margin-top: 10px;
}

input {
	padding: 10px;
	margin-top: 5px;
	/* border: 1px solid #ccc; */
	border-radius: 3px;
}

button[type="submit"] {
	margin-top: 20px;
	padding: 10px;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 3px;
	cursor: pointer;
}

button[type="submit"]:hover {
	background-color: #0056b3;
}

.blur-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(5px);
	z-index: 999;
}

.login_box_box {

	width: 400px;
	height: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.3);
}

.login_box {
	width: 80%;
	height: 20%;
	display: flex;
	flex-direction: row;
	margin: 10px auto;
	/* margin-left: 15%; */
	background-color: #484848;
	align-items: center;
	border-radius: 12px;
	justify-content: center;
	cursor: pointer;


}

.google_text {
	/*gontinue with google*/

	/* left: 107px;
		top: 3px; */
	width: 90%;
	height: auto;
	opacity: 1;
	/** 文本1 */
	font-size: 20px;
	font-weight: 800;
	align-items: center;
	align-content: space-between;
	letter-spacing: 0px;
	line-height: 20px;
	color: #f5f2f4;
	vertical-align: top;
	/* border: #007bff solid 1px; */
}

.login_icon {
	width: 31px;
	height: 31px;
	/* margin-left: 15px; */
	background-size: 90%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	/* border:#f60e44 solid 1px; */
	/* margin-left: 10px; */
	margin-right: 10px;
}

.content {
	padding: 20px;
}

/* .container {
	display: flex;
	align-items: center;
	padding: 20px;
} */

.container {
	/*路径*/

	left: 0px;
	top: 0px;
	width: auto;
	height: auto;
	background: url("../assets/background.png");
	overflow: hidden;

}

.content_box_height {
	height: 650px;
	/* border: #de11e5 solid 1px; */
}

.topcontainer {

	/* border: #007bff solid 1px; */
	overflow: hidden;
	width: auto;
	height: 60px;
	background: url("../assets/daohanglan.png");
	/* overflow: hidden; */
	/* padding: auto; */
	display: flex;
	/* 如果需要水平居中也可以添加： */
	align-items: center;
	/* 设置高度，例如： */
	/* height: 100vh; */
}

.logotype {
	/* border: #343a40 solid 1px; */
	/* float: left; */
	margin-left: -1.8vw;
	margin-right: 20px;
	margin-top: 20px;
	/* background-image: url("../assets/face_swapping_logo.png"); */
	background-size: contain;
	width: auto;
	/* 父容器宽度 */
	height: auto;
	/* 父容器高度，可以根据需要调整 */
	background-position: center;
	/* 图片居中 */
	background-repeat: no-repeat;
	/* 防止图片重复 */
	overflow: hidden;
	/* border-radius: 50%; */
	/* border:#007bff solid 1px; */

}

.logotype img {
	width: 15vw;
	height: auto;
}

.linktype {
	/* border: #f462c8 solid 1px; */
	display: flex;
	/* justify-content:center; */
	/* 如果需要水平居中也可以添加： */
	align-items: center;
	width: 70%;
	margin-left: 0px;
	/* 设置高度，例如： */
	/* height: 100vh; */
}

.gongneng {
	/* border: #343a40 solid 1px; */
	display: flex;
	float: left;
	margin-left: 0px;
	margin-right: 30px;
	padding: 20px 0;
	/*sign up*/

	/* font-size: 24px; */
	font-weight: 200;
	letter-spacing: 0px;
	line-height: 26px;
	color: rgb(248, 238, 238);
	align-items: center;
	/* 垂直居中对齐 */
	justify-content: center;
	/* 水平居中对齐 */
	text-align: center;
	vertical-align: top;
	background-color: transparent;
	border: none;
	cursor: pointer;
	/* outline: none; */
}

.gongneng span {
	white-space: nowrap;
	/* 防止文字换行 */
	font-size: 1.5em;
	/* 使用相对单位 */
}

.dropdown {

	display: flex;
	position: absolute;
	top: 30px;
	right: 0;
	width: auto;
	height: 200px;
	background-color: rgb(63, 62, 63);
	/* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
	color: #f5f2f4;
	padding: 10px;
	z-index: 1000;
	flex-direction: column;
	margin: 20px auto;
	/* border: none; */
	cursor: pointer;
	/* border: #007bff solid 1px; */
	gap: 10px;
	overflow: hidden;
}

.dropdown button {
	width: 120px;
	/* 控制按钮宽度 */
	height: 40px;
	/* 控制按钮高度 */
	padding: 0;
	/* 去除默认 padding */
	font-size: 16px;
	/* 控制字体大小 */
	border: none;
	/* 去除默认边框 */
	background-color: #6e7072;
	/* 按钮背景颜色 */
	color: white;
	/* 按钮文字颜色 */
	cursor: pointer;
	/* 鼠标悬停时显示为指针 */
	border-radius: 5px;
	/* 按钮圆角 */
}

.gongneng:hover {
	color: #976af2;
	/* 悬浮时背景颜色变化 */
	transition: color 0.3s ease, transform 0.3s ease;
	/* transform: translateY(-5px); */
	/* 悬浮时向上移动 */
	/* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); */
	/* 悬浮时添加阴影 */
}

.gongneng:active {
	background-color: transparent;
}



.logintype {
	/* border: #00ff40; */
	float: right;
	display: flex;
	align-items: center;
	margin-left: auto;
	margin-right: 30px;
	font-size: 24px;
	font-weight: 400;
	padding: 20px 0;
}

.content_box {
	/* float: left; */
	/* border: #f5f2f4 solid 1px; */
	display: flex;
	/* align-items: center; */
	overflow: hidden;
	/* position: relative; */

}

.content_weight {
	margin-left: 70px;
}

.swap_box {
	/*路径*/
	/* left: 183px;
		top: 220px; */
	position: relative;
	display: flex;
	margin-top: 40px;
	margin-right: 10px;
	height: auto;
	align-items: center;
	flex-direction: column;
	/* justify-content:center; */
	/* text-align: center; */
	/* justify-content:flex-end; */
	width: 65%;
	/* opacity: 1; */
	/* border: #ef24c0 solid 1px; */
	background: rgb(249, 250, 250);
}

.col_content {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	/* border: #ef24c0 solid 1px; */
}

.col_content2 {
	position: relative;
	display: flex;
	/* align-items:center; */

	flex-direction: column;
	/* border: #ef24c0 solid 1px; */
}

.row_content_back {
	background: rgb(247, 242, 242);
	/*路径*/
}

.row_content {
	position: relative;
	display: flex;
	/* align-items: center;
		justify-content:center; */
	flex-direction: row;
	/* border: hsl(167, 67%, 67%) solid 1px; */
}

.row_content_margin {

	margin-left: 10px;
	margin-top: 10px;
	width: 100%;
	height: 50%;
}

.row_content_margin_child {

	margin-left: 10px;
	margin-top: 10px;
	margin-right: 20px;
	position: relative;
	width: 55%;
	height: 90%;
	object-fit: cover;
	border-radius: 12px;
	/* border: #b07ee2 solid 1px; */
	/* overflow: hidden; */
	/* background-size: 100% 100%; */
}

.row_content_margin_child.hover {
	background-color: #838385;
	/* 更深的灰色背景 */
	border-color: #888;
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
	/* 更深的阴影效果 */
}

.click_generate {

	/*路径*/

	/* left: 383px;
	top: 843px; */
	margin-top: 2vw;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 70%;
	height: 4vw;
	font: 100 24px/1.5 PingFang SC, sans-serif;
	color: #f5f2f4;
	opacity: 1;
	background: rgb(158, 156, 156);
	border-radius: 20px;
	cursor: pointer;
	/* background-color: transparent; */
	border: none;
	cursor: pointer;

}

.click_generate_upload {

	/*路径*/

	/* left: 383px;
	top: 843px; */
	margin-top: 2vw;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 70%;
	height: 4vw;
	font: 300 24px/1.5 PingFang SC, sans-serif;
	color: #f5f2f4;
	;
	opacity: 1;
	background: rgb(16, 127, 238);
	border-radius: 20px;
	cursor: pointer;
	/* background-color: transparent; */
	border: none;
	cursor: pointer;

}

/* .loading-spinner {
	border: 4px solid rgba(0, 0, 0, 0.1);
	width: 36px;
	height: 36px;
	border-radius: 50%;
	border-top-color: #3498db;
	animation: spin 1s linear infinite;
} */
.loading-spinner {
	width: 36px;
	height: 36px;
	background-image: url('../assets/loading2.png');
	/* 替换为你的图片路径 */
	background-size: cover;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.img {

	/* max-width:  90%;
	height: 90%; */
	/* 可以根据需要调整高度 */
	/* background-image: url("https://img.mobiuspace.com/image/aigc/95698695c820af829243c7d0eeb4579d.webp"); */
	/* background-size: 80%; */
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	/* 保持图片原始宽高比，覆盖整个容器 */
	/* border: #007bff solid 1px; */



	/* overflow: hidden; */

}

.upload_img {
	/* width: 60%;
	/* height: 90%; */

	background-size: 60% auto;
	background-position: center;
	background-repeat: no-repeat;
	object-fit: cover;
	background-image: url("../assets/upload-img.png");
	background-color: transparent;
	border: none;
	cursor: pointer;

}

.upload_img:hover {
	color: #976af2;
	/* 悬浮时背景颜色变化 */
	transition: color 0.3s ease, transform 0.3s ease;
	transform: translateY(-5px);
	/* 悬浮时向上移动 */
	/* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); */
	/* 悬浮时添加阴影 */
}



.tempalte_box {
	/*路径*/

	/* left: 1273px;
		top: 97px; */
	position: relative;
	display: flex;
	margin-left: auto;
	margin-top: 40px;
	margin-right: 30px;
	/* margin-bottom: 900; */
	width: 30%;
	height: auto;
	opacity: 1;
	/* border: #7610f2 solid 1px; */
	background: rgba(21, 14, 38, 1);
}

.swap_word1 {
	/*free face swap online*/

	/* left: 435px;
		top: 241px; */
	margin-top: -3vw;
	/* margin-bottom: 0.5vw; */
	width: 100%;
	height: 7rem;
	text-align: center;
	/* opacity: 1; */
	/* display: flex; */
	/* position: absolute; */
	/** 文本1 */
	font-size: 2.5rem;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 1.5rem;
	color: rgba(0, 0, 0, 1);
	vertical-align: top;
	/* border:#007bff solid 1px */
}

.swap_word2 {
	/*experience fun or real swaps: select sin*/

	/* left: 290px;
		top: 329px;
		width: 810px;
		height: 29px; */
	/* position: absolute; */
	display: flex;
	justify-content: center;
	opacity: 1;
	/** 文本1 */
	margin-top: -2rem;
	display: flex;
	font-size: calc(20px + 0.5vw);
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 3rem;
	color: rgba(0, 0, 0, 1);
	vertical-align: top;
	padding: 5px;
	/* border: #ef24c0 solid 1px; */
}

.font_name3 {
	/*face swap*/

	/* left: 462px;
		top: 373px; */
	/* width: 118px;
		height: 35px; */
	opacity: 1;
	position: relative;
	/** 文本1 */
	margin-top: 3px;
	margin-left: 30px;
	margin-right: 30px;
	display: flex;
	font-size: 24px;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 35px;
	color: rgba(112, 112, 112, 1);
	text-align: right;
	vertical-align: top;
	/* border: #00ff40 solid 1px; */
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.font_name3.isActive::before {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 3px;
	background: linear-gradient(to right, #1f1f1f, #007bff);
	border-radius: 8px;
	bottom: -10px
}

.font3_box {
	/* border: #00ff40 solid 1px; */
	margin-top: -1rem;
	margin-left: 30px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	/* margin-top: 20px;
	margin-left: 30px; */
	/* justify-content: space-between; */
	/* float:inline-start; */
	width: 90%;
	height: auto;
}

.template_img {
	position: relative;
	display: flex;
	gap: 15px;
	flex-wrap: wrap;
	/* border: #00ff40 solid 1px; */
	/* margin-bottom: 10px; */
}

.template_img img {
	position: relative;
	width: calc(50% - 20px);
	height: auto;
	justify-content: space-between;
	margin: 5px;
	/* margin-top: 50px; */
	/* margin-bottom: 10px; */
	/* width: 20%; */
	object-fit: cover;
	border-radius: 10px;
	/* 50%宽度减去间距 */
	box-sizing: border-box;
	align-items: center;

}

.selected-image {
	border-color: #b07ee2 solid 2px;
}

.template_type {

	display: flex;
	width: 100%;
	height: auto;
	position: relative;
	margin-top: 80px;
	display: flex;
	background: rgba(44, 39, 56, 1);
	/* align-items:center; */
	flex-direction: column;
	/* border: #ef24c0 solid 1px; */
}

.template_type_select {

	/*sporty*/

	/* left: 26px;
	top: 5.5px;
	width: 50px;
	height: 23px;
	opacity: 1; */
	/** 文本1 */
	display: flex;
	position: relative;
	margin: 5px;
	border-radius: 8%;
	/* padding: auto; */
	text-align: center;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 23px;
	color: #f5f2f4;
	/* border: none; */
	/* background: seashell; */
	vertical-align: top;
	background-color: #01080f;
	cursor: pointer;

	/*路径*/

	/* left: 0px;
	top: 0px; */
	width: 102px;
	height: 35px;
	/* opacity: 1; */
	/* border: 1px solid rgba(72, 52, 212, 1); */
	background-color: transparent;
	border: none;
	cursor: pointer;

}

/* .template_type_select:hover { */
/* background-color: rgb(246, 246, 248); */
/* 悬停时背景颜色变为深蓝色 */
/* } */


.template_type_select.isActive {
	/*路径*/

	/* left: 0px;
		top: 0px; */
	/* content: ''; */
	/* position: absolute; */
	/* left: 0; */
	/* width: 102px;
		height: 35px; */
	/* opacity: 1; */
	background: rgba(104, 109, 224, 1);
	box-shadow: 0px 1px 2px rgba(20, 14, 62, 0.25);
	z-index: 2;
}

.upload_template {
	position: absolute;
	display: flex;
	margin-top: 20px;
	margin-bottom: 50px;
	margin-left: 20px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 90%;
	height: 30px;

	/*路径*/
	/* width: 506px; */
	/* height: 40px; */
	/* opacity: 1; */
	background: rgba(204, 204, 204, 0.3);

	/** 文本1 */
	font-size: 20px;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 38px;
	color: #f5f2f4;
	;
	vertical-align: top;
	/* border: #007bff solid 1px; */
	cursor: pointer;
	/* background-color: transparent; */
	border: none;
	/* cursor: pointer; */
}

.img_doc {
	width: auto;
	height: 40vw;
	display: flex;
	/* position: relative; */
	/* border: #00ff40 solid 1px; */
	/* align-items: center;
	justify-content: center; */
	flex-direction: row;

}

.img_doc1_box {
	/*face-swap-and-demonstrate-the-ability-to-generate-various-different-faces-of-a-man 1*/
	display: flex;
	margin-top: 5vw;
	overflow: hidden;
	width: 50%;
	height: 419px;
}


.img_doc1_box_box {
	/*create infinite faces with al face swap technology*/

	/* left: 915px; 
		top: 1168px; */
	/* margin-top: 50%;
	margin-left: 50%; */
	/* margin: 10px; */
	/* float: left; */
	position: relative;
	display: flex;
	width: 50%;
	margin-top: 5vw;
	height: auto;
	opacity: 1;
	/* border: #ff0055 solid 1px; */
	flex-direction: column;
	align-items: center;
	justify-content: center;

}

.img_doc1_box_doc1 {
	/*create infinite faces with al face swap technology*/

	/* left: 915px; 
		top: 1168px; */
	margin-top: 0vw;
	margin-left: 1vw;
	position: absolute;
	display: flex;
	width: 90%;
	height: auto;
	opacity: 1;
	/** 文本1 */
	font-size: 20px;
	font-weight: 400;
	letter-spacing: 0px;
	color: #f5f2f4;
	;
	vertical-align: top;
	/* border: #007bff solid 1px; */
}

.img_doc1_box_doc2 {

	/*ai face swap has surged in popularity as a dynamic photo trend. its advanced algorithms accurately detect and track facial features, allowing users to effortlessly create realistic face swaps. Pook ai face swapper is one of the best free online tools for face swapping, you can use it to seamlessly swap face in any photo and enjoy endless fun while engaging with realistic face swap photos.*/

	margin-top: 8vw;
	margin-left: 1vw;
	width: 90%;
	height: auto;
	opacity: 1;
	position: absolute;
	display: flex;
	/** 文本1 */
	font-size: 26px;
	font-weight: 400;
	letter-spacing: 0px;
	/* line-height: 12px; */
	color: #f5f2f4;
	;
	vertical-align: top;
	/* border: #f462c8 solid 1px; */
}

.swap_boten {
	display: flex;
	position: absolute;
	margin-top: 20vw;
	margin-left: 10vw;
	width: 358px;
	height: 92px;
	opacity: 1;
	background: rgba(72, 52, 212, 1);
	/* border: #00ff40 solid 1px; */
	cursor: pointer;
	border: none;
}

.swap_boten.text {

	width: 178px;
	height: 38px;
	opacity: 1;
	/** 文本1 */
	font-size: 26px;
	font-weight: 400;
	align-items: center;
	justify-content: center;
	letter-spacing: 0px;
	line-height: 38px;
	color: #f5f2f4;
	;
	text-align: center;
	vertical-align: top;
}

.doc2_box {
	/* left: 500px;
		top: 1886px; */
	/* margin-left: 5vw; */

	display: flex;
	align-items: center;
	justify-content: center;
	/* margin-top: 2vw; */
	margin: 2vw auto;
	width: 90%;
	height: 160px;
	opacity: 1;
	font-size: 40px;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 60px;
	color: #f5f2f4;
	;
	/* background: #d7e3f0; */
	vertical-align: top;
	text-align: center;
	/* 文字向左对齐 */
	word-wrap: break-word;
	/* 允许长单词或 URL 地址换行到下一行 */
	/* border: #7610f2 solid 1px;; */
}

.row_doc_box_base {

	display: flex;
	position: relative;
	float: left;
	margin-top: 10px;
	margin: 10px auto;
	width: auto;
	height: auto;
	/* opacity: 1; */
	flex-direction: row;
	/* overflow: hidden; */
	flex-wrap: wrap;
	/* 允许换行 */
	justify-content: space-between;
	/* 项目之间的间距均匀分布 */
	/* border: #00ff40 solid 1px; */
	overflow: hidden;
}

.col_doc_box_base {

	display: flex;
	position: relative;
	float: left;
	margin-top: 10px;
	width: auto;
	height: 40vw;
	opacity: 1;
	flex-direction: column;
	/* overflow: hidden; */
	/* flex-wrap: wrap; */
	/* 允许换行 */
	/* justify-content: space-between; */
	/* 项目之间的间距均匀分布 */
	/* border: #00ff40 solid 1px; */
	overflow: hidden;
}

/* 第一块图文 */
.doc3_box_1 {

	/*rectangle 13*/


	/* position: relative; */
	/* margin-top: 10px; */
	/* margin-left: 5vw; */
	margin: 10px auto;
	/* left: 0px;
	top: 100px; */
	width: 31%;
	height: 40%;
	/* opacity: 1; */
	/* background: rgba(40, 25, 65, 1); */
	/* border: #ef24c0 solid 1px; */
}

.doc3_box_1_image {
	max-width: 65%;
	/* 图片最大宽度为容器的100% */
	max-height: 65%;
	/* 图片最大高度为容器的100% */
	display: block;
	/* 将图片转换为块级元素 */
	margin: 0 auto;
	/* 水平居中 */
	object-fit: cover;
	overflow: hidden;
}

.doc3_box_1_text_box {

	/* opacity: 1; */
	display: flex;
	/* line-height: 1vw; */
	flex-direction: column;
	padding-left: 1vw;
	padding-right: 1vw;
	/* padding-bottom: 1vw; */
	padding-top: 0.15vw;
	border-radius: 10px;
	background: rgba(255, 255, 255, 1);
	height: 11vw;
}




.doc3_box_1_text {


	/*ai face swap has surged in popularity*/

	/* left: 83px;
	top: 2399px;
	width: 474px;
	height: 40px;
	opacity: 1; */
	/** 文本1 */

	font-size: 1.4em;
	font-weight: 350;
	letter-spacing: 0px;
	line-height: auto;
	color: rgba(0, 0, 0, 1);
	vertical-align: top;
	/* width: 90%; */
	/* 文本最大宽度为容器的100% */
	/* display: block; */
	/* 将文本转换为块级元素 */
	/* margin: 0 auto; */
	/* 水平居中 */
	/* text-align: left; */
	/* 文字向左对齐 */
	text-wrap: wrap;
	/* 允许长单词或 URL 地址换行到下一行 */
	/* font-size: 24px; */
	/* color: #f5f2f4; */
	/* border: #ff0055 solid 1px;; */
}


.doc3_box_1_text2 {

	/** 文本1 */
	font-size: 1em;
	font-weight: 00;
	letter-spacing: 0px;
	line-height: auto;
	color: rgba(0, 0, 0, 1);
	vertical-align: top;
	text-wrap: wrap;

}

.swap_button_style {
	width: 8em;
	height: 2em;
	opacity: 1;
	font-size: 1.3em;
	font-weight: 100;
	color: #f5f2f4;
	background: rgba(72, 52, 212, 1);
	/* border: #00ff40 solid 1px; */
	cursor: pointer;
	border-radius: 15px;
	border: none;
}

.box_center {
	display: flex;
	margin: 0 auto;
	justify-content: center;
	align-items: center;
}

/* 第3块图文 */
.box_text_base {

	margin-left: 5vw;
	/* padding: 2vw; */
	font-size: 1.4em;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: auto;
	color: rgb(250, 247, 247);
	vertical-align: top;
	text-align: center;
	/* width: 90%; */
	/* 文本最大宽度为容器的100% */
	/* display: block; */
	/* 将文本转换为块级元素 */
	/* margin: 0 auto; */
	/* 水平居中 */
	/* text-align: left; */
	/* 文字向左对齐 */
	word-wrap: break-word;

}


.box_text_base2 {

	/** 文本1 */
	margin-top: 2vw;
	margin-left: 5vw;
	font-size: 1em;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: auto;
	color: rgb(248, 245, 245);
	vertical-align: top;
	word-wrap: break-word;

}

.box_img_base {

	/* max-width: 50%; */
	/* 图片最大宽度为容器的100% */
	float: left;
	max-width: 90%;
	max-height: auto;
	/* 图片最大高度为容器的100% */
	display: block;
	/* 将图片转换为块级元素 */
	margin: 0 auto;
	/* 水平居中 */
	object-fit: cover;
}

.fdvfd {
	/*rectangle 14*/

	/* left: 1104px;
		top: 3571px; */
	/* margin-top: 7vw; */
	margin: 6vw auto;
	width: 616px;
	height: 453px;
	opacity: 1;
	background: rgba(217, 217, 217, 0);
	border: 2px solid rgba(79, 142, 217, 1);
	/* overflow: hidden; */
	/* object-fit:auto; */
}


.buy_back_box {
	/*rectangle 23*/

	/* left: 109px;
		top: 8298px; */
	display: flex;
	/* position: absolute; */
	width: 30%;
	height: auto;
	opacity: 1;
	border-radius: 8px;
	background: #c2cad2;
	flex-direction: column;
	border-radius: 12px;
	align-items: center;
	/* justify-content: center; */
	/* background: rgba(255, 255, 255, 1); */
	/* border: #007bff solid 1px; */
}

.buy_back_box_text {
	/*free and quaility face swaps*/

	/* left: 119px;
		top: 8322px; */
	/* width: 470px;
		height: 46px; */
	display: flex;
	/* justify-items: center; */
	align-items: center;
	/* justify-content: space-between; */
	/* margin: 2px auto; */
	width: 100%;
	height: 60px;
	border-radius: 12px;
	/** 文本1 */
	font-size: 1.5rem;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: auto;
	color: rgb(11, 11, 11);
	vertical-align: top;
	background-color: #f5f2f4;

}

.buy_back_box_text2 {
	/*free and quaility face swaps*/
	/* margin: 2px auto; */
	/** 文本1 */
	display: flex;
	/* margin-top: 50px; */
	width: 80%;
	height: auto;
	padding: 1em;
	font-size: 1rem;
	font-weight: 300;
	letter-spacing: 0px;
	line-height: auto;
	color: rgb(25, 24, 24);
	vertical-align: top;
	flex-wrap: wrap;
	text-align: left;
	align-items: center;
	justify-content: center;
	border-radius: 12px;

	/* overflow: scroll; */

}

.buy_back_box_text img {

	padding: 10px;

	width: 50px;
	/* 设置图片的宽度 */
	height: 50px;
	/* 设置图片的高度 */
	border-radius: 50%;
	/* 将图片裁剪成圆形 */
	object-fit: cover;
	/* 确保图片内容被裁剪成圆形 */
	margin-left: 10px;
}

.stars {
	/*star 1*/
	/* position: absolute; */

	margin: 1vw auto;
	/* border: #00ff40 solid 1px; */
	/* width: auto; */
	/* height: 30px; */
	/* display: flex; */
	margin-top: 10px;
	width: 90%;
	height: auto;
	text-align: center;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	/* object-fit: cover; */
}

.faq {

	/*1.can i face swap pnline for free*/
	display: flex;
	align-items: center;
	justify-content: left;
	margin: 5vw auto;
	width: 80%;
	height: 46px;
	opacity: 1;
	/** 文本1 */
	font-size: 20px;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 46px;
	color: rgba(255, 255, 255, 1);
	vertical-align: top;
	/* border: #a694a0 solid 1px; */
}

.conect_us {

	display: flex;
	align-items: center;
	justify-content: left;
	/* margin: 30px auto; */
	flex-direction: row;
	color: #f5f2f4;
	letter-spacing: 2px;
	word-spacing: 2px;
	border-top: #3e3f3e solid 2px;

}

.connect_us_box {
	display: flex;
	margin: 10px;
}

/* 小屏幕设备 */
@media screen and (max-width: 720px) {


	.h1 {
		font-size: 2rem;
		margin-top: 3rem;
	}

	.container {
		display: flex;
		flex-direction: column;
	}

	.content_box_height {
		height: 500px;
		/* border: #de11e5 solid 1px; */
	}

	.topcontainer {
		height: 10vw;
		/* border: #f5f2f4 solid 2px; */
	}

	.logotype {
		/* border: #343a40 solid 1px; */
		/* float: left; */
		margin-left: -5vw;
		margin-top: 10px;
		width: auto;
		/* 父容器宽度 */
		height: auto;
		/* 父容器高度，可以根据需要调整 */
		overflow: hidden;
	}

	.logotype img {
		width: 30vw;
		height: auto;
	}

	.gongneng {
		/* border: #343a40 solid 1px; */
		float: left;
		margin-left: 0px;
		margin-right: 10px;
		padding: 6px 0;
		/*sign up*/

		font-size: 12px;
		font-weight: 200;
		letter-spacing: 0px;
		line-height: 10px;
		color: rgb(59, 57, 57);
		text-align: center;
		vertical-align: top;
		background-color: transparent;
		border: none;
		cursor: pointer;
		/* border:#ee0505 solid 1px; */
		/* outline: none; */
	}

	.gongneng:hover {
		color: #976af2;
		/* 悬浮时背景颜色变化 */
		transition: color 0.3s ease, transform 0.3s ease;
		transform: translateY(0px);
		/* 悬浮时向上移动 */
		/* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); */
		/* 悬浮时添加阴影 */
	}

	.gongneng:active {
		background-color: transparent;
	}

	.linktype {
		display: none;
	}

	.logintype {
		/* border: #00ff40; */
		float: right;
		display: flex;
		align-items: center;
		margin-left: auto;
		margin-right: 15px;
		font-size: 12px;
		font-weight: 200;
		padding: 8px 0;
	}

	.content_box {
		/* float: left; */
		/* border: #f5f2f4 solid 1px; */
		display: flex;
		/* align-items: center; */
		overflow: hidden;
		/* position: relative; */
		flex-direction: column;

	}

	.content_weight {
		margin-left: 30px;
	}

	.swap_box {
		margin-top: auto;
		margin-right: 0px;
		margin-left: 0%;
		width: auto;
		overflow: hidden;
		/* border: #de11e5 solid 1px; */
	}

	.col_content {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		/* border: #ef24c0 solid 1px; */
	}

	.col_content2 {
		position: relative;
		display: flex;
		/* align-items:center; */

		flex-direction: column;
		/* border: #ef24c0 solid 1px; */
	}

	.row_content_back {
		background: rgb(247, 242, 242);
		/*路径*/
	}

	.row_content {
		position: relative;
		display: flex;
		/* align-items: center;
			justify-content:center; */
		flex-direction: row;
		/* border: hsl(167, 67%, 67%) solid 1px; */
	}

	.row_content_margin {

		margin-left: 10px;
		margin-top: 10px;
		width: 100%;
		height: 50%;
	}

	.row_content_margin_child {

		margin-left: 10px;
		margin-top: 10px;
		margin-right: 20px;
		position: relative;
		width: 55%;
		height: 90%;
		object-fit: cover;
		border-radius: 12px;
		/* overflow: hidden; */
		/* background-size: 100% 100%; */
	}

	.click_generate {

		/*路径*/

		/* left: 383px;
		top: 843px; */
		margin-top: 5vw;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 70%;
		height: 10vw;
		font: 100 12px/1.5 PingFang SC, sans-serif;
		color: rgba(255, 255, 255, 1);
		opacity: 1;
		background: rgb(158, 156, 156);
		border-radius: 20px;
		cursor: pointer;
		/* background-color: transparent; */
		border: none;
		cursor: pointer;
		/* border: orchid solid 1px; */

	}

	.click_generate_upload {

		/*路径*/

		/* left: 383px;
		top: 843px; */
		margin-top: 5vw;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 70%;
		height: 10vw;
		font: 300 18px/1.5 PingFang SC, sans-serif;
		color: rgba(255, 255, 255, 1);
		opacity: 1;
		background: rgb(16, 127, 238);
		border-radius: 20px;
		cursor: pointer;
		/* background-color: transparent; */
		border: none;
		cursor: pointer;

	}

	.img {

		/* width: 90%;
		height: 90%; */
		/* 可以根据需要调整高度 */
		/* background-image: url("https://img.mobiuspace.com/image/aigc/95698695c820af829243c7d0eeb4579d.webp"); */
		background-size: 80%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		max-width: 100px;
		/* 保持图片原始宽高比，覆盖整个容器 */
		/* border: #007bff solid 1px; */



		/* overflow: hidden; */

	}

	.upload_img {
		/* width: 60%;
		/* height: 90%; */

		background-size: 50%;
		background-position: center;
		background-repeat: no-repeat;
		object-fit: cover;
		background-image: url("../assets/icon-upload.368442cb.png");
		background-color: transparent;
		border: none;
		cursor: pointer;
		/* border:#976af2 solid 1px; */

	}

	.upload_img:hover {
		color: #976af2;
		/* 悬浮时背景颜色变化 */
		transition: color 0.3s ease, transform 0.3s ease;
		transform: translateY(-5px);
		/* 悬浮时向上移动 */
		/* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); */
		/* 悬浮时添加阴影 */
	}



	.tempalte_box {
		/*路径*/

		/* left: 1273px;
			top: 97px; */
		position: relative;
		display: flex;
		margin-left: 10px;
		margin-top: 10px;
		margin-right: 10px;
		/* margin-bottom: 900; */
		width: 100%;
		height: auto;
		opacity: 1;
		/* border: #7610f2 solid 1px; */
		background: rgba(21, 14, 38, 1);
	}

	.swap_word1 {
		/*free face swap online*/

		/* left: 435px;
			top: 241px; */
		margin-top: -5vw;
		width: 100%;
		height: 15vw;
		text-align: center;
		/* opacity: 1; */
		/* display: flex; */
		/* position: absolute; */
		/** 文本1 */
		font-size: 1.5em;
		font-weight: 300;
		letter-spacing: 0px;
		line-height: 2rem;
		color: rgba(0, 0, 0, 1);
		vertical-align: top;
		/* border:#007bff solid 1px */
	}

	.swap_word2 {
		/*experience fun or real swaps: select sin*/

		/* left: 290px;
			top: 329px;
			width: 810px;
			height: 29px; */
		/* position: absolute; */
		display: flex;
		justify-content: center;
		opacity: 1;
		/** 文本1 */
		width: 100%;
		text-align: center;
		margin-top: 1rem;
		display: flex;
		font-size: 1em;
		font-weight: 400;
		letter-spacing: 0px;
		line-height: 2rem;
		color: rgba(0, 0, 0, 1);
		vertical-align: top;
		padding: 1px;
		/* border: #ef24c0 solid 1px; */
	}

	.font_name3 {
		/*face swap*/

		/* left: 462px;
			top: 373px; */
		/* width: 118px;
			height: 35px; */
		opacity: 1;
		position: relative;
		/** 文本1 */
		margin-top: px;
		margin-left: 15px;
		margin-right: 15px;
		display: flex;
		font-size: 18px;
		font-weight: 300;
		letter-spacing: 0px;
		line-height: 1.2rem;
		color: rgba(112, 112, 112, 1);
		text-align: right;
		vertical-align: top;
		/* border: #00ff40 solid 1px; */
		background-color: transparent;
		/* border: darkgoldenrod;
			border-radius: 8%; */
		cursor: pointer;
		/* border: #976af2 solid 1px */
	}

	.font_name3.isActive::before {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 3px;
		background: linear-gradient(to right, #1f1f1f, #007bff);
		border-radius: 5px;
		bottom: -5px
	}

	.font3_box {
		/* border: #00ff40 solid 1px; */
		margin-top: 8px;
		margin-left: 30px;
		margin-bottom: 8px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		/* margin-top: 20px;
		margin-left: 30px; */
		/* justify-content: space-between; */
		/* float:inline-start; */
		width: 90%;
		height: auto;
		/* border: #007bff solid 1px */
	}

	.template_img {
		position: relative;
		display: flex;
		gap: 5px;
		flex-wrap: wrap;
		/* border: #00ff40 solid 1px; */
		/* margin-bottom: 10px; */
	}

	.template_img img {
		position: relative;
		width: calc(50% - 20px);
		height: auto;
		justify-content: space-between;
		margin: 5px;
		/* margin-top: 50px; */
		/* margin-bottom: 10px; */
		/* width: 20%; */
		object-fit: cover;
		border-radius: 10px;
		/* 50%宽度减去间距 */
		box-sizing: border-box;
		align-items: center;

	}

	.selected-image {
		border-color: #b07ee2 solid 2px;
	}

	.template_type {

		display: flex;
		width: 100%;
		height: auto;
		position: relative;
		margin-top: 80px;
		display: flex;
		background: rgba(44, 39, 56, 1);
		/* align-items:center; */
		flex-direction: column;
		/* border: #ef24c0 solid 1px; */
	}

	.template_type_select {

		/*sporty*/

		/* left: 26px;
		top: 5.5px;
		width: 50px;
		height: 23px;
		opacity: 1; */
		/** 文本1 */
		display: flex;
		position: relative;
		margin: 5px;
		/* padding: auto; */
		text-align: center;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0px;
		line-height: 23px;
		color: rgba(255, 255, 255, 1);
		/* border: none; */
		/* background: seashell; */
		vertical-align: top;
		background-color: #01080f;
		cursor: pointer;

		/*路径*/

		/* left: 0px;
		top: 0px; */
		width: 102px;
		height: 35px;
		/* opacity: 1; */
		/* border: 1px solid rgba(72, 52, 212, 1); */
		background-color: transparent;
		border: none;
		cursor: pointer;

	}

	/* .template_type_select:hover { */
	/* background-color: rgb(246, 246, 248); */
	/* 悬停时背景颜色变为深蓝色 */
	/* } */


	.template_type_select.isActive {
		/*路径*/

		/* left: 0px;
			top: 0px; */
		/* content: ''; */
		/* position: absolute; */
		/* left: 0; */
		/* width: 102px;
			height: 35px; */
		/* opacity: 1; */
		background: rgba(104, 109, 224, 1);
		box-shadow: 0px 1px 2px rgba(20, 14, 62, 0.25);
		z-index: 2;
	}

	.upload_template {
		position: absolute;
		display: flex;
		margin-top: 20px;
		margin-bottom: 50px;
		margin-left: 20px;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 90%;
		height: 30px;

		/*路径*/
		/* width: 506px; */
		/* height: 40px; */
		/* opacity: 1; */
		background: rgba(204, 204, 204, 0.3);

		/** 文本1 */
		font-size: 20px;
		font-weight: 400;
		letter-spacing: 0px;
		line-height: 38px;
		color: rgba(255, 255, 255, 1);
		vertical-align: top;
		/* border: #007bff solid 1px; */
		cursor: pointer;
		/* background-color: transparent; */
		border: none;
		/* cursor: pointer; */
	}

	.img_doc {
		width: auto;
		height: 100vw;
		display: flex;
		/* position: relative; */
		/* border: #00ff40 solid 1px; */
		/* align-items: center;
		justify-content: center; */
		flex-direction: column;

	}

	.img_doc1_box {
		/*face-swap-and-demonstrate-the-ability-to-generate-various-different-faces-of-a-man 1*/
		display: flex;
		margin-top: 5vw;
		overflow: hidden;
		width: 100%;
		height: 300px;
	}


	.img_doc1_box_box {
		/*create infinite faces with al face swap technology*/

		/* left: 915px; 
			top: 1168px; */
		/* margin-top: 50%;
		margin-left: 50%; */
		/* margin: 10px; */
		/* float: left; */

		display: flex;
		width: 100%;
		margin-top: 5vw;
		align-items: center;
		padding: auto;
		height: auto;
		opacity: 1;
		flex-direction: column;
		/* border: #007bff solid 1px; */
	}

	.img_doc1_box_doc1 {
		/*create infinite faces with al face swap technology*/

		/* left: 915px; 
			top: 1168px; */
		/* margin-top: 3vw;
			margin-left: 3vw; */
		display: flex;
		width: 90%;
		height: auto;
		opacity: 1;
		padding: auto;
		/** 文本1 */
		font-size: 10px;
		font-weight: 200;
		letter-spacing: 0px;
		color: rgba(255, 255, 255, 1);
		vertical-align: top;
		text-wrap: wrap;
		/* border: #007bff solid 1px; */
	}

	.img_doc1_box_doc2 {

		/*ai face swap has surged in popularity as a dynamic photo trend. its advanced algorithms accurately detect and track facial features, allowing users to effortlessly create realistic face swaps. Pook ai face swapper is one of the best free online tools for face swapping, you can use it to seamlessly swap face in any photo and enjoy endless fun while engaging with realistic face swap photos.*/


		width: 90%;
		height: auto;
		opacity: 1;
		display: flex;
		/** 文本1 */
		font-size: 13px;
		font-weight: 200;
		letter-spacing: 0px;
		/* line-height: 12px; */
		color: rgba(255, 255, 255, 1);
		vertical-align: top;
		text-wrap: wrap;
		/* border: #f462c8 solid 1px; */
	}

	.swap_boten {
		display: flex;
		margin-left: 10vw;
		width: 200px;
		height: 92px;
		opacity: 1;
		background: rgba(72, 52, 212, 1);
		/* border: #00ff40 solid 1px; */
		cursor: pointer;
		border: none;
	}

	.swap_boten.text {

		width: 178px;
		height: 38px;
		opacity: 1;
		/** 文本1 */
		font-size: 20px;
		font-weight: 300;
		align-items: center;
		justify-content: center;
		letter-spacing: 0px;
		line-height: 38px;
		color: rgba(255, 255, 255, 1);
		text-align: center;
		/* vertical-align: top; */
	}

	.doc2_box {
		/* left: 500px;
			top: 1886px; */
		/* margin-left: 5vw; */

		display: flex;
		align-items: center;
		justify-content: center;
		/* margin-top: 2vw; */
		margin: 2vw auto;
		width: 90%;
		height: 160px;
		opacity: 1;
		font-size: 1.5em;
		font-weight: 400;
		letter-spacing: 0px;
		line-height: 30px;
		color: rgba(255, 255, 255, 1);
		vertical-align: top;
		text-align: center;
		/* 文字向左对齐 */
		word-wrap: break-word;
		/* 允许长单词或 URL 地址换行到下一行 */
		/* border: #7610f2 solid 1px;; */
	}

	.row_doc_box_base {

		display: flex;
		/* position: relative; */
		float: left;
		margin-top: 10px;
		margin: 0px auto;
		width: auto;
		height: auto;
		padding: 10px;
		/* opacity: 1; */
		flex-direction: column;
		/* overflow: hidden; */
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		/* 允许换行 */
		/* justify-content: space-between; */
		/* 项目之间的间距均匀分布 */
		/* border: #00ff40 solid 1px; */
		/* overflow: scroll; */
		/* border:#976af2 solid 1px; */
	}

	.col_doc_box_base {

		display: flex;
		position: relative;
		float: left;
		margin-top: 10px;
		width: auto;
		height: 40vw;
		opacity: 1;
		flex-direction: column;
		/* overflow: hidden; */
		/* flex-wrap: wrap; */
		/* 允许换行 */
		/* justify-content: space-between; */
		/* 项目之间的间距均匀分布 */
		/* border: #00ff40 solid 1px; */
		overflow: hidden;
	}

	/* 第一块图文 */
	.doc3_box_1 {

		/*rectangle 13*/

		/* display: flex;
			position: relative; */
		/* margin-top: 10px; */
		/* margin-left: 5vw; */
		margin: 10px auto;
		/* left: 0px;
		top: 100px; */
		width: 100%;
		height: 40%;
		/* opacity: 1; */
		/* background: rgba(40, 25, 65, 1); */
	}

	.doc3_box_1_image {
		max-width: 80%;
		/* 图片最大宽度为容器的100% */
		max-height: auto;
		/* 图片最大高度为容器的100% */
		display: block;
		/* 将图片转换为块级元素 */
		margin: 0 auto;
		/* 水平居中 */
		object-fit: cover;
		overflow: hidden;
	}

	.doc3_box_1_text_box {

		opacity: 1;
		border-radius: 10px;
		background: rgba(255, 255, 255, 1);
		height: 38%;
	}


	.doc3_box_1_text {


		/*ai face swap has surged in popularity*/

		/* left: 83px;
		top: 2399px;
		width: 474px;
		height: 40px;
		opacity: 1; */
		/** 文本1 */

		font-size: 1.4em;
		font-weight: 350;
		letter-spacing: 0px;
		line-height: auto;
		color: rgba(0, 0, 0, 1);
		vertical-align: top;
		/* width: 90%; */
		/* 文本最大宽度为容器的100% */
		/* display: block; */
		/* 将文本转换为块级元素 */
		/* margin: 0 auto; */
		/* 水平居中 */
		/* text-align: left; */
		/* 文字向左对齐 */
		word-wrap: break-word;
		/* 允许长单词或 URL 地址换行到下一行 */
		/* font-size: 24px; */
		/* color: #f5f2f4; */
		/* border: #ff0055 solid 1px;; */
	}


	.doc3_box_1_text2 {

		/** 文本1 */
		font-size: 1em;
		font-weight: 00;
		letter-spacing: 0px;
		line-height: auto;
		color: rgba(0, 0, 0, 1);
		vertical-align: top;
		word-wrap: break-word;

	}

	.swap_button_style {
		width: 8em;
		height: 2em;
		opacity: 1;
		font-size: 1.3em;
		font-weight: 100;
		color: #f5f2f4;
		background: rgba(72, 52, 212, 1);
		/* border: #00ff40 solid 1px; */
		cursor: pointer;
		border-radius: 15px;
		border: none;
	}

	.box_center {
		display: flex;
		margin: 0 auto;
		justify-content: center;
		align-items: center;
	}

	/* 第3块图文 */
	.box_text_base {

		margin-left: 2vw;
		font-size: 1.4em;
		font-weight: 350;
		letter-spacing: 0px;
		line-height: auto;
		color: rgb(250, 247, 247);
		vertical-align: top;
		width: 90%;
		align-content: center;
		/* 文本最大宽度为容器的100% */
		/* display: block; */
		/* 将文本转换为块级元素 */
		/* margin: 0 auto; */
		/* 水平居中 */
		text-align: left;
		/* 文字向左对齐 */
		word-wrap: break-word;

	}


	.box_text_base2 {

		/** 文本1 */
		width: 100%;
		height: auto;
		margin-top: 2vw;
		margin-left: 5vw;
		font-size: 1em;
		font-weight: 200;
		letter-spacing: 0px;
		line-height: auto;
		color: rgb(248, 245, 245);
		vertical-align: top;
		/* word-wrap:break-word; */
		text-wrap: wrap;
		overflow-wrap: break-word;
		/* 允许在单词内换行 */
		word-wrap: break-word;
		/* 兼容旧版浏览器 */
		overflow: auto;
		/* 如果内容超出容器大小，显示滚动条 */
		padding: 10px;

	}

	.box_img_base {

		/* max-width: 50%; */
		/* 图片最大宽度为容器的100% */
		float: left;
		max-width: 90%;
		max-height: auto;
		/* 图片最大高度为容器的100% */
		display: block;
		/* 将图片转换为块级元素 */
		margin: 0 auto;
		/* 水平居中 */
		object-fit: cover;
	}

	.fdvfd {
		/*rectangle 14*/

		/* left: 1104px;
			top: 3571px; */
		/* margin-top: 7vw; */
		margin: 10px auto;
		width: 90%;
		height: auto;
		opacity: 1;
		background: rgba(217, 217, 217, 0);
		/* overflow: hidden; */
		/* object-fit:auto; */
	}


	.buy_back_box {
		/*rectangle 23*/

		/* left: 109px;
			top: 8298px; */
		/* display: flex;
			position: absolute; */
		width: 90%;
		height: auto;
		opacity: 1;
		border-radius: 8px;
		/* background: rgba(255, 255, 255, 1); */
		/* border: #007bff solid 1px; */
	}

	.buy_back_box_text {
		/*free and quaility face swaps*/

		/* left: 119px;
			top: 8322px; */
		/* width: 470px;
			height: 46px; */
		display: flex;
		justify-items: center;
		align-items: center;
		margin: 2px auto;
		/* margin-left: 10px; */
		width: 100%;
		height: 60px;
		text-align: center;
		/** 文本1 */
		font-size: 1.5rem;
		font-weight: 400;
		letter-spacing: 0px;
		line-height: auto;
		color: rgb(11, 11, 11);
		vertical-align: top;
		background-color: #f5f2f4;
		border-radius: 12px;
		/* border: #ef24c0 solid 1px; */

	}

	.buy_back_box_text2 {
		/*free and quaility face swaps*/
		margin: 2px auto;
		padding: 10px;
		/** 文本1 */
		width: 100%;
		height: auto;
		font-size: 1rem;
		font-weight: 300;
		text-wrap: wrap;
		letter-spacing: 0px;
		line-height: auto;
		color: rgb(252, 250, 250);
		vertical-align: top;
		border-radius: 12px;
		/* border: #ef24c0 solid 1px; */
		overflow: scroll;

	}

	.stars {
		/*star 1*/
		/* position: absolute; */

		margin: 1vw auto;
		/* border: #00ff40 solid 1px; */
		/* width: auto; */
		/* height: 30px; */
		/* display: flex; */
		text-align: center;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		/* object-fit: cover; */
	}

	.faq {

		/*1.can i face swap pnline for free*/
		display: flex;
		align-items: center;
		justify-content: left;
		margin: 5vw auto;
		width: 80%;
		height: 20px;
		opacity: 1;
		/** 文本1 */
		font-size: 20px;
		font-weight: 200;
		letter-spacing: 0px;
		line-height: 20px;
		color: rgba(255, 255, 255, 1);
		vertical-align: top;
		/* border: #a694a0 solid 1px; */
	}

	.conect_us {

		display: flex;
		align-items: center;
		justify-content: left;
		/* margin: 30px auto; */
		flex-direction: row;
		color: #f5f2f4;
		letter-spacing: 2px;
		word-spacing: 2px;
		border-top: #3e3f3e solid 2px;

	}

	.connect_us_box {

		/* height: 500px; */

		display: flex;
		margin-top: 10px;
		/* margin: 10px; */
		font-size: 10px;
		font-weight: 200;
		letter-spacing: 0px;
		line-height: 40px;
		align-items: center;
		justify-content: left;
		/* margin: 30px auto; */
		flex-direction: row;
		color: #f5f2f4;
		letter-spacing: 2px;
		word-spacing: 2px;
		/* border-top: #3e3f3e solid 2px; */
	}
}
</style>