import { createRouter, createWebHistory } from "vue-router";
import SwapFace from "@/views/SwapFace.vue";
import NotFound from "@/views/NotFound.vue";
import ArticleList from "@/views/ArticleList.vue";
import ClothsPron from "@/views/ClothsPron.vue";
import PhotoEnhancer from "@/views/PhotoEnhancer.vue";
import PhotorRestore from "@/views/PhotorRestore.vue";
import TxtImage from "@/views/TxtImage.vue";
import HomePage from "@/views/HomePage.vue";
import i18n from "@/i18n"; // 引入 i18n 实例

const supportedLocales = [
  "en",
  "ar",
  "de",
  "es",
  "fr",
  "hi",
  "it",
  "ja",
  "ko",
  "nl",
  "pt",
  "ru",
  "tr",
  "vi",
  "zhCN",
  "zhTW",
];
// const defaultLanguage = 'en' // 默认语言
const localePattern = `(${supportedLocales.join("|")})?`;
console.log("localePattern", localePattern);
const routes = [
  {
    path: `/:locale${localePattern}`, // 支持所有支持的语言前缀或者不带语言前缀
    component: {
      template: "<router-view />",
    },
    children: [
      {
        path: "",
        name: "home-page",
        component: HomePage,
      },
      {
        path: "cloth-swap",
        name: "undress-cloths",
        component: ClothsPron,
      },
      {
        path: "face-swap",
        name: "face-swap",
        component: SwapFace,
      },
      {
        path: "photo-enhancer",
        name: "photo-enhancer",
        component: PhotoEnhancer,
      },
      {
        path: "photo-restoration",
        name: "photo-restoration",
        component: PhotorRestore,
      },
      {
        path: "ai-image-generate",
        name: "image-generate",
        component: TxtImage,
      },
      {
        path: "blogs",
        name: "BlogList",
        component: ArticleList,
      },
      {
        path: ":page.html",
        name: "html-page",
        component: () => import("@/views/HtmlViewer.vue"),
        props: true,
        alias: [
          // "/:locale/:page.html", // 添加别名路由
        ],
      },
    ],
  }, // en, ar,de,es,fr,hi,it,ja,ko,pt,ru,nl,tr,vi,zhCN,zhTW
  {
    path: "/:pathMatch(.*)*",
    name: "NotFoundRoot",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const newLocale = to.params.locale; // 获取当前路由的语言参数
  console.log("router.beforeEach1", to.fullPath.includes("tr/"), from, to);
  // if (!newLocale) {
  //   // 如果没有语言参数，重定向到默认语言
  //   // next(`/${i18n.global.locale}`);
  //   return;
  // }

  // 检查语言是否存在
  // if (["tr", "es", "ar", "vi"].includes(to.fullPath.split("/")[1])) {
  //   const newPath = to.fullPath.replace(/^\/(tr|es|ar|vi)/, "");
  //   // 保持路径不变，直接导航到目标路径
  //   next({ path: newPath, query: to.query, hash: to.hash, replace: true });
  //   console.log("router.newPath2", newPath, from, to);
  //   return;
  // }

  // 检查语言是否存在
  if (newLocale != "" && newLocale && !supportedLocales.includes(newLocale)) {
    // 如果语言不存在，重定向到 404 页面
    console.log("router.newLocale3", newLocale);
    next({ name: "NotFoundRoot" });
    return;
  }

  // 更新 i18n 的 locale
  if (newLocale && i18n.global.locale !== newLocale) {
    i18n.global.locale = newLocale;
    console.log("i18n.global.locale", i18n.global.locale);
  }

  // 继续导航
  next();
});

export default router;
