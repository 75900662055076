<template>
	<div class="container">
		<div
			style="display: flex;justify-content: center;width: 100%;height: auto;align-items: center;flex-direction: column;">
			<h1>AI Image Generator</h1>
			<p>This is an AI Image Generator. It creates an image from scratch from a text description.</p>
		</div>

		<!-- <div class="option">
			<label for="prompt">Create an image from text prompt</label>
		</div> -->
		<div
			style="display: flex;flex-direction: row;margin-left: 2vw;width: 100%;height: auto;align-items: center;justify-content: center;">
			<div class="option">
				<textarea id="prompt" v-model="prompt" placeholder="输入您的提示，或点击生成以获取灵感" maxlength="200" rows="4" cols="30"
					@input="handleInput" class="text-input"></textarea>
			</div>
			<button class="button" @click="generateImage">Generate</button>
		</div>
		<div style="display: flex;margin-left: 10px;flex-direction: column;">
			<div class="option">
				<label for="style">Choose a style</label>
			</div>
			<div class="style-options">
				<div class="style-option" :class="{ selected: selectedStyle === 1 }" style="background-color: #fff;"
					@click="selectedStyle = 1"></div>
				<div class="style-option" :class="{ selected: selectedStyle === 2 }" style="background-color: #ccc;"
					@click="selectedStyle = 2"></div>
				<div class="style-option" :class="{ selected: selectedStyle === 3 }" style="background-color: #aaa;"
					@click="selectedStyle = 3"></div>
			</div>
			<button class="button" @click="viewAllStyles">Advanced Setting</button>
		</div>

	</div>
</template>

<script>
export default {
	data() {
		return {
			prompt: '',
			selectedStyle: null
		};
	},
	methods: {
		generateImage() {
			// Handle image generation logic here
			console.log('Generate image:', this.prompt, this.selectedStyle);
		}
	}
};
</script>

<style>
/* body {
	color: #fff;
	justify-content: center;
	display: flex;
	align-items: center;
} */

.container {
	padding: 20px;
	color: #fff;
	border-radius: 10px;
	width: 100%;
	height: auto;
	/* justify-content: center; */
	display: flex;
	/* align-items: center; */
	flex-direction: column;
}

.button {
	background-color: #6a0dad;
	color: #fff;
	border: none;
	padding: 10px;
	border-radius: 5px;
	margin: 10px 0;
	cursor: pointer;
	width: auto;
}

.button:hover {
	background-color: #5a0cad;
}

.option {
	display: flex;
	align-items: center;
	margin: 10px 0;
	width: 70%;
}

.text-input {
	display: flex;
	align-items: center;
	margin: 10px 0;
	box-sizing: border-box;
	height: 70px;
	width: 100%;
	padding: 12px 12px;
	font-size: 1em;
	border-radius: 12px;
	border: 1px solid rgba(74, 66, 97, 1);
	background: rgba(45, 40, 60, 1);
	color: white;
	margin: 10px;
	margin-top: 0px;
	margin-bottom: 0px;
	font-family: inherit;
	resize: none;

}

.option input {
	margin-right: 10px;
}

.style-options {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	margin-bottom: 20px;
}

.style-option {
	width: 50px;
	height: 50px;
	margin: 5px;
	border: 2px solid #fff;
	border-radius: 5px;
	cursor: pointer;
}

.style-option.selected {
	border-color: #6a0dad;
}
</style>